/** BASE CSS FILE FOR A BASE DEFINITION **/

body {
    overflow-x: hidden;
}

/* fallback */

@font-face {
    font-family: 'Material Icons';
    src: local('Material Icons'), url('assets/fonts/MaterialIcons-Regular.eot'), url('assets/fonts/MaterialIcons-Regular.woff') format('woff') url('assets/fonts/MaterialIcons-Regular.woff2') format('woff2'), url('assets/fonts/MaterialIcons-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}